<template>
  <section class="invoice-preview-wrapper">
    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        md="12"
        xl="12"
      >
        <b-card
          class="invoice-preview-card"
          no-body
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h2><b>{{ data.client.name }}</b></h2>
                  <!--                  <h3 class="text-primary invoice-logo">
                                      OCS
                                    </h3>-->
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-0">
                <!--                <ShipmentBarCode :value="data.awb"/>-->
                <!--                <h4 class="invoice-title">
                                  AWB No.
                                  <span class="invoice-number">{{ data.awb }}</span>
                                </h4>-->
                <div class="invoice-date-wrapper pt-2 pb-0">
                  <p class="invoice-date-title">
                    Invoice No.:
                  </p>
                  <p class="invoice-date">
                    #{{ data.awb }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                class="p-0"
                cols="12"
                xl="6"
              >
                <h3 class="mb-2">
                  Sender Information:
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        <b>Name</b>
                      </td>
                      <td>{{ data.client ? data.client.name : '' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>Account</b>
                      </td>
                      <td>{{ data.client.client_no }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>Phone</b>
                      </td>
                      <td>{{ data.client ? data.client.phone : '' }}, {{ data.client ? data.client.mobile : '' }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>City</b>
                      </td>
                      <td>{{ data.client.city.title }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>County</b>
                      </td>
                      <td>{{ data.client.city.country.title }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>Address:</b>
                      </td>
                      <td>{{ data.client_address ? data.client_address.address : '' }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                cols="12"
                xl="6"
              >
                <div>
                  <h3 class="mb-2">
                    Consignee Information:
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <b>Name:</b>
                        </td>
                        <td><span class="font-weight-bold">{{
                          data.consignee_name ? data.consignee_name : (data.consignee ? data.consignee.name : '')
                        }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Area:</b>
                        </td>
                        <td>{{ data.area }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>City:</b>
                        </td>
                        <td>{{ data.city ? data.city.title : '' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Country:</b>
                        </td>
                        {{ data.city ? data.city.country.title : '' }}
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Address:</b>
                        </td>
                        <td>{{
                          data.address ? data.address : data.consignee ? data.consignee.addresses[0].name : ''
                        }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Phone:</b>
                        </td>
                        <td>{{ data.phone1 ? data.phone1 : data.consignee ? data.consignee.phone : '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <table class="table table-responsive-sm">
            <tr>
              <th>Description</th>
              <th>Origin</th>
              <th>Weight</th>
              <!--              <th>Pieces</th>-->
              <th>Price</th>
            </tr>
            <tr
              v-for="item in data.items"
              :key="item.id"
            >
              <td>{{ item.description_of_goods }}</td>
              <td>{{ item.country_of_origin }}</td>
              <td>{{ item.weight }}</td>
              <!--              <td>{{ item.pieces }}</td>-->
              <td>{{ item.price }}</td>
            </tr>
            <!--                        <template #cell(taskDescription)="data">
                                        <b-card-text class="font-weight-bold mb-25">
                                            {{ data.item.taskTitle }}
                                        </b-card-text>
                                        <b-card-text class="text-nowrap">
                                            {{ data.item.taskDescription }}
                                        </b-card-text>
                                    </template>-->
          </table>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                class="mt-md-0 mt-3"
                cols="12"
                md="6"
                order="2"
                order-md="1"
              >
                <!--                                <b-card-text class="mb-0">
                                                    <span class="font-weight-bold">Salesperson:</span>
                                                    <span class="ml-75">Alfie Solomons</span>
                                                </b-card-text>-->
              </b-col>

              <!-- Col: Total -->
              <b-col
                class="mt-md-6 d-flex justify-content-end"
                cols="12"
                md="6"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{ data.items_price }} $
                    </p>
                  </div>
                  <!--                                    <div class="invoice-total-item">
                                                          <p class="invoice-total-title">
                                                              Discount:
                                                          </p>
                                                          <p class="invoice-total-amount">
                                                              0
                                                          </p>
                                                      </div>-->
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Fees:
                    </p>
                    <p class="invoice-total-amount">
                      {{ data.fees }} $
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ data.items_price + data.fees }} $
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold"><b>Terms & Condition:</b></span>
            <span>The above‐ mentioned value declared for customs purpose only. I/We hereby certify that the
              information on this invoice is true and correct and that the contents of this shipment are as stated above</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <!--            <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      class="invoice-actions"
                  >
                      <b-card>

                          &lt;!&ndash; Button: Send Invoice &ndash;&gt;
                          <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-toggle.sidebar-send-invoice
                              variant="primary"
                              class="mb-75"
                              block
                          >
                              Send Invoice
                          </b-button>

                          &lt;!&ndash; Button: DOwnload &ndash;&gt;
                          <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="outline-secondary"
                              class="mb-75"
                              block
                          >
                              Download
                          </b-button>

                          &lt;!&ndash; Button: Print &ndash;&gt;
                          <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="outline-secondary"
                              class="mb-75"
                              block
                              @click="printInvoice"
                          >
                              Print
                          </b-button>

                          &lt;!&ndash; Button: Edit &ndash;&gt;
                          <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="outline-secondary"
                              class="mb-75"
                              block
                              :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }"
                          >
                              Edit
                          </b-button>

                          &lt;!&ndash; Button: Add Payment &ndash;&gt;
                          <b-button
                              v-b-toggle.sidebar-invoice-add-payment
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="success"
                              class="mb-75"
                              block
                          >
                              Add Payment
                          </b-button>
                      </b-card>
                  </b-col>-->
    </b-row>
    <div class="page-break" />
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  props: ['data'],
  data() {
    return {
      invoiceDescription: [{
        description_of_goods: this.data.description_of_goods,
        country_of_origin: this.data.country_of_origin,
        weight: this.data.weight,
        pieces: this.data.pieces,
      }],
      fields: ['description_of_goods', 'country_of_origin', 'weight', 'pieces'],
    }
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
