<template>
  <div>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      block
      class="mb-75 action_button w-25 ml-auto"
      variant="primary"
      @click="printInvoice"
    >
      Print
    </b-button>
    <ShipmentInvoiceDetails
      v-for="shipment in shipments"
      :key="shipment.id"
      :data="shipment"
    />
  </div>
</template>

<script>
import {
  BButton, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// eslint-disable-next-line import/extensions
import ShipmentInvoiceDetails from '@/views/shipments/ShipmentInvoiceDetails'
import axios from '@/libs/axios'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    ShipmentInvoiceDetails,
    BButton,
  },
  props: [],
  data() {
    return {
      shipments: [],
      loading: true,
      fields: ['description_of_goods', 'country_of_origin', 'weight', 'pieces'],
    }
  },
  created() {
    this.filter_shipment()
  },
  methods: {
    filter_shipment() {
      this.loading = true
      const filter = []
      const shipmentsArr = JSON.parse(this.$route.query.shipments)
      if (shipmentsArr !== 0) {
        filter.push({ field: 'id', operator: 'in', value: shipmentsArr })
        axios.post('/shipments/search', {
          filters: filter,
        }).then(res => {
          this.shipments = res.data.data
          this.loading = false
          setTimeout(() => {
            this.printInvoice()
          }, 500)
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
      }
    },
    printInvoice() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
    font-size: 18px;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .content-header {
    display: none;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
  .action_button {
    display: none;
  }
}
</style>
